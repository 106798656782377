import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/RadialMenu.css";
import Header from '../Header';

// Импорт локальных изображений (пути нужно заменить на ваши)
import planeIcon from "../assets/icons/plane.png";
import trainIcon from "../assets/icons/train.png";
import transferIcon from "../assets/icons/transfer.png";
import carrentIcon from "../assets/icons/carrent.png";
import hotelIcon from "../assets/icons/hotel.png";
import insuranceIcon from "../assets/icons/insurance.png";
import rusIcon from "../assets/icons/rus.png";
import worldIcon from "../assets/icons/world.png";

const RadialMenu = () => {
    const navigate = useNavigate();
    const [hoveredSection, setHoveredSection] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const START_SECTION_ID = "s7"; // ID секции, которая должна быть первой
    const sections = [
        { id: "train-tickets", title: "ж/д билеты", icon: trainIcon, path: "/trains" },
        { id: "s7", title: "авиабилеты", icon: planeIcon, path: "/s7" },
        { id: "transfers", title: "трансферы", icon: transferIcon, path: "/transfers" },
        { id: "russia-tours", title: "туры по россии", icon: rusIcon, path: "/russia-tours" },
        { id: "carrent", title: "аренда авто", icon: carrentIcon, path: "/car-rent" },
        { id: "hotels", title: "гостиницы", icon: hotelIcon, path: "/hotels" },
        { id: "insurance", title: "страхование", icon: insuranceIcon, path: "/insurance" },
        { id: "abroad-tours", title: "туры за рубеж", icon: worldIcon, path: "/abroad-tours" },
    ];

    const handleClick = (path) => {
        navigate(path);
    };

    // Находим индекс стартовой секции
    const startIndex = sections.findIndex(s => s.id === START_SECTION_ID);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <>
            <Header />
            <div className="radial-menu-container">
                <div className="radial-menu">
                    <div className="menu-backdrop"></div>
                    {sections.map((section, index) => {
                        const rotation = index * 45;
                        const animationOrder = (index - startIndex + sections.length) % sections.length;

                        return (
                            <div
                                key={section.id}
                                className={`menu-item ${hoveredSection === section.id ? 'hovered' : ''}`}
                                style={{ transform: `rotate(${rotation}deg)` }}
                                onMouseEnter={() => setHoveredSection(section.id)}
                                onMouseLeave={() => setHoveredSection(null)}
                                onClick={() => handleClick(section.path)}
                            >
                                <div className="sector-mask"></div>
                                <div className="icon-wrapper" style={{ transform: `rotate(-${rotation}deg)` }}>
                                    <img
                                        src={section.icon}
                                        alt={section.title}
                                        className={`menu-icon ${isMounted ? "fade-in" : ""}`}
                                        style={{
                                            "--delay": `${animationOrder * 0.1}s`
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    <div className="center-label">
                        {hoveredSection && sections.find(s => s.id === hoveredSection)?.title}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RadialMenu;
