import React, { useState, useCallback, useEffect } from 'react';
import '../styles/DoubleTimeSlider.css';

const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};

const DoubleTimeSlider = ({
                              label,
                              min = 0,
                              max = 1440, // 24 часа в минутах
                              step = 5,
                              lowerValue,
                              upperValue,
                              onChangeLower,
                              onChangeUpper
                          }) => {
    const [activeHandle, setActiveHandle] = useState(null);
    const [sliderRect, setSliderRect] = useState(null);

    const updateSliderRect = useCallback(() => {
        const container = document.querySelector('.slider-container');
        if (container) setSliderRect(container.getBoundingClientRect());
    }, []);

    const handleMove = useCallback((e) => {
        if (!sliderRect || !activeHandle) return;

        let rawValue = ((e.clientX - sliderRect.left) / sliderRect.width) * (max - min) + min;
        rawValue = Math.max(min, Math.min(rawValue, max));

        const roundedValue = Math.round(rawValue / step) * step;

        if (activeHandle === 'lower') {
            const newLower = Math.min(roundedValue, upperValue - step);
            if (newLower !== lowerValue) onChangeLower(newLower);
        } else {
            const newUpper = Math.max(roundedValue, lowerValue + step);
            if (newUpper !== upperValue) onChangeUpper(newUpper);
        }
    }, [activeHandle, lowerValue, upperValue, min, max, step, sliderRect]);

    useEffect(() => {
        if (activeHandle) {
            updateSliderRect();
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', () => setActiveHandle(null));
            return () => {
                document.removeEventListener('mousemove', handleMove);
            };
        }
    }, [activeHandle, handleMove, updateSliderRect]);

    return (
        <div className="time-filter">
            <div className="filter-header">
                <span className="filter-label">{label}</span>
                <span className="time-range">
          {formatTime(lowerValue)} - {formatTime(upperValue)}
        </span>
            </div>

            <div
                className="slider-container"
                onMouseDown={updateSliderRect}
                style={{ padding: '20px 0' }}
            >
                <div className="slider-track">
                    <div
                        className="slider-range"
                        style={{
                            left: `${((lowerValue - min) / (max - min)) * 100}%`,
                            width: `${((upperValue - lowerValue) / (max - min)) * 100}%`,
                        }}
                    />

                    <div
                        className="slider-handle lower"
                        style={{ left: `${((lowerValue - min) / (max - min)) * 100}%` }}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setActiveHandle('lower');
                        }}
                    >
                        <div className="tooltip">{formatTime(lowerValue)}</div>
                    </div>

                    <div
                        className="slider-handle upper"
                        style={{ left: `${((upperValue - min) / (max - min)) * 100}%` }}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setActiveHandle('upper');
                        }}
                    >
                        <div className="tooltip">{formatTime(upperValue)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoubleTimeSlider;